import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Masonry from "react-masonry-component";

import classNames from "classnames";
import * as styles from "./Grid.module.scss";

// Masory Options
const masonryOptions = {
  fitWidth: false,
  columnWidth: styles.grid,
  gutter: 0,
  itemSelector: styles.grid,
};

const Grid = ({ items, type }) => {
  // complex masonry stuff with gatsby
  // https://medium.com/@kripod/building-highly-performant-masonry-layouts-with-gatsby-js-54115acc3e72
  return (
    <div>
      {/* <button onClick={() => setSimple(!simple)}>
        {simple ? "show images" : "text only"}
      </button> */}
      <section className={styles.grid}>
        <Masonry
          className={styles.masonry}
          // elementType={"ul"}
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
        >
          {items &&
            items.map((item, index) => {
              // const ranGrid = Math.floor(Math.random() * 3);
              // const cls = classNames(
              //   { [styles.twoOneGrid]: ranGrid === 0 },
              //   { [styles.oneTwoGrid]: ranGrid === 1 },
              //   { [styles.fullGrid]: ranGrid === 2 },
              //   styles.gridItem
              // );
              return (
                <article className={styles.gridItem} key={index}>
                  <Link key={index} to={`/${type}/${item.slug.current}`}>
                    <GatsbyImage
                      image={item.mainImage.asset.gatsbyImageData}
                      className={styles.masonryImage}
                      // aspectRatio={16 / 3}
                      // remove alt as caption provides it
                      alt={item.shortTitle || item.title}
                    />
                  </Link>
                </article>
              );
            })}
        </Masonry>
      </section>
    </div>
  );
};

export { Grid };
