import React, { useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Grid } from "../../common/Grid";

const WorksPage = ({ works }) => {
  const [simple, setSimple] = useState(false);
  return (
    <>
      {/* <div>
        <h1 className="visually-hidden">Works</h1>
      </div> */}
      {works && <Grid items={works.map(({ node }) => node)} type="work" />}
    </>
  );
};

export { WorksPage };
